import React, { Component } from 'react';

class Tools extends Component {
  render(){
    return (
      <div className="componentContainer">
        <h1>Tools</h1>
        <div className="grid-container">
          <div className="grid-item">
            <a href="https://eu-west-2.console.aws.amazon.com/console/home?region=eu-west-2">
              AWS Login
            </a>
          </div>
          <div className="grid-item">
            <a href="https://trello.com/login" target="blank">
              Trello
            </a>
          </div>
          <div className="grid-item">
            <a href="http://35.176.50.20:8080/" target="blank">
              Jenkins
            </a>
          </div>
          <div className="grid-item">
            <a href="http://35.176.50.20/phpmyadmin/" target="blank">
              phpMyAdmin
            </a>
          </div>
          <div className="grid-item">
            <a href="https://rollbar.com/login/" target="blank">
              Rollbar
            </a>
          </div>
          <div className="grid-item">
            <a href="https://slack.com/signin/" target="blank">
              Slack
            </a>
          </div>
          <div className="grid-item">
            <a href="https://grafana.com/login" target="blank">
              Grafana
            </a>
          </div>
          <div className="grid-item">
            <a href="https://portal.kobiton.com/login" target="blank">
              Kobiton
            </a>
          </div>
          <div className="grid-item">
            <a href="https://crucible.testrail.com/" target="blank">
              TestRail
            </a>
          </div>
          <div className="grid-item">
            <a href="https://tracker.gaminglabs.com:8443/secure/Dashboard.jspa" target="blank">
              GLI JIRA
            </a>
          </div>
          <div className="grid-item">
            <a href="https://tu.enea.sk/" target="blank">
              TextureUnpacker
            </a>
          </div>
          <div className="grid-item">
            <a href="https://ogs-gdm-gdk-cruciblesoftware.nyxaws.net:3333/" target="blank">
              SG GUT
            </a>
          </div>
          <div className="grid-item">
            <a href="https://crucibledev.net/guillotine/" target="blank">
              Guillotine
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Tools;
