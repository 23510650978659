import React, { Component } from 'react';
import './welcome.css';

class Welcome extends Component {
  render(){
    return (
      <div className="welcome-wrapper">
        <div className="centered-element">
            <h1>::: the forge :::</h1>
        </div>
      </div>
    )
  }
}

export default Welcome;
