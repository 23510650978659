/**
* ARIS LAUNCHER STUFF
*/
export var ARIS_COOKIE_USER = "devlogin";
export var ARIS_COOKIE_GAME = "folder";
export var ARIS_COOKIE_DESTINATION = "dest";
export var ARIS_COOKIE_ENDPOINT = "endpoint";
export var ARIS_USERS = ["Mark","Louis","Oliver","Ed","JamesT","Jordan","Mike","Kent","Briggy","Birchy","Robin", "Test12", "Test13", "Test14", "Test15", "Test16", "DoNotUse", "Sudeep", "LiamMcD","Josh", "Conor", "David", "Joanne"];
export var ARIS_GAMES = [
  "Select Game",
  "AnvilTemplate",
  "BeijingNights",
  "BookOf7s",
  "BrightStar",
  "CandylandCash",
  "DaVinciJewels",
  "FinlaysFortunes",
  "Fortune8",
  "GigaFruits",
  "GoldStrike",
  "HawaiianDreams",
  "JingleBellJackpots",
  "MiamiDice",
  "MillionairesLife",
  "MississippiWilds",
  "Quarterback",
  "RedHotSlots",
  "ReturnOfRa",
  "RichesOfTheIncas",
  "SlotsOfMoney",
  "SmokinHotSlots",
  "SultanOfSpins",
  "VegasTriplePayDeluxe",
  "WellOilBe",
  "WishMeLuck",
  "WheelBigWinner",
  "Vegas3XDeluxe",
  "VegasTriplePaySpinNWin",
  "WheelBigWinnerRedHotSpins",
  "WheelBigWinnerMillionairesLife"
];
export var ARIS_LOCATIONS = ["AWS","Stage","Prod"];
export var ARIS_SERVICE_OPTIONS = [
  {id: '4', label: 'Saucify/Kore 4'},
  {id: '7', label: 'Saucify/Kore 7'},
  {id: '13', label: 'Saucify/Kore 13'},
  {id: '16', label: 'Saucify/Kore 16'},
  {id: '20', label: 'Saucify/Kore 20'},
  {id: '3', label: 'aristxPLAY-03'},
  {id: '14', label: 'aristxPLAY-14'},
  {id: '16', label: 'aristxPLAY-16'},
  {id: '17', label: 'aristxPLAY-17'},
  {id: '20', label: 'aristxPLAY-20'},
  {id: '21', label: 'aristxPLAY-21'},
  {id: '24', label: 'aristxPLAY-24'},
  {id: '27', label: 'aristxPLAY-27'},
  {id: '30', label: 'aristxPLAY-30'}
]

// var ARIS_DEFAULT_KORE_SERVICE_ID = 13;   // The current default Kore server to use

/**
 * Backend
 */
  // export function ARIS_SetBackendServices(){
  //     let serviceId = parseInt(ARIS_getCookie(ARIS_COOKIE_ENDPOINT),10);
  //     serviceId = isNaN(serviceId) ? ARIS_DEFAULT_KORE_SERVICE_ID : serviceId;

  //     return serviceId;
  // }
/* ************************************************************************
 * Cookie stuff
 */
 export function ARIS_setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  export function ARIS_getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
