import React, { Component } from 'react';

class Midas extends Component {
  render(){
    return (
        <div className="componentContainer">
            <h1>MIDAS Production</h1>
            <iframe id="midasProduction"
                    title="MIDAS Production"
                    width="100%"
                    height="600px"
                    src="https://app.databox.com/datawall/6c956a004e1162ea6ebd43d45ff8b10d06149c309">
            </iframe>

            <h1>MIDAS Stage</h1>
            <iframe id="midasStage"
                    title="MIDAS Stage"
                    width="100%"
                    height="600px"
                    src="https://app.databox.com/datawall/ae285234c8f2046df62b0cbce940db650610d2a59">
            </iframe>
        </div>
    )
  }
}

export default Midas;