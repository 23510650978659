import React, { Component } from 'react';

class Hr extends Component {
  render(){
    return (
      <div className="componentContainer">
        <h1>HR & Employment Stuff</h1>
        <div className="grid-container">
          <div className="grid-item">
            <a href="https://www.irisopenpayslips.co.uk/Account/LogOn">
              Payslip Portal
            </a>
          </div>
          <div className="grid-item">
            <a href="/" target="blank">
              Company Handbook
            </a>
          </div>
          <div className="grid-item">
            <a href="https://app.path.co.uk/login-redirect" target="blank">
              Path
            </a>
          </div>
          <div className="grid-item">
            <a href="https://www.bike2workscheme.co.uk/" target="blank">
              Bike2Work
            </a>
          </div>
          <div className="grid-item">
            <a href="/" target="blank">
              Gym Membership
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Hr;
