import React, { Component } from 'react';
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  HashRouter
} from 'react-router-dom';
import './App.css';
import Welcome from './welcome';
import DevArea from './devarea';
import Midas from './midas';
import Documentation from './documentation';
import Tools from './tools';
import Hr from './hr';
import Ukgc from './ukgc'
import {PostData} from './services/PostData';

class App extends Component {
  constructor() {
    super();

    this.state = {
      date: this.getCurrentDate(),
      email: '',
      errorMessage: '',
      ip: '',
      isAuthenticated: false,
      loginError: false,
      password: '',
      username: ''
    }

    this.closeNav = this.closeNav.bind(this);
    this.getCurrentDate = this.getCurrentDate.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.login = this.login.bind(this);
    this.logOut = this.logOut.bind(this);

    fetch(`https://geolocation-db.com/json/`)
      .then(res => res.json())
      .then(json => this.setState({ip: json.IPv4}))
  }

  componentDidMount() {
    const app = this;
    function getAuthentication() {
        fetch('./authenticate.php')
        .then(response => {
          return response.text()
        })
        .then(data => JSON.parse(data))
        .then(function(data){
          if(data.user_id){
            app.setState({
              isAuthenticated: true,
              username: data.user_name
            })
          }
        }
      );
    }

    getAuthentication();
  }

  closeNav(){
    let ref = 'nav';
    this.refs[ref].checked = !this.refs[ref].checked;
  }

  getCurrentDate(separator=''){
    var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    var today  = new Date();

    return today.toLocaleDateString("en-US", options)
  }

  handleOnChange(e) {
    this.setState({[e.target.name]:e.target.value});
  }

  login(event) {
    event.preventDefault();
    if(this.state.email && this.state.password){
      PostData('login',this.state).then((result) => {
        let responseJson = result;

        console.log(result);

        console.log(`user is ${result.user}`);
        if(responseJson.user){
          sessionStorage.setItem('userData',JSON.stringify(responseJson));
          this.setState({
            isAuthenticated: true,
            username: responseJson.user
          });
        } else {
          console.log('error');
          this.setState({
            errorMessage: 'Username or Password is incorrect',
            loginError: true
          });
        }
      });
    }
  }

  logOut(event){
    this.closeNav();

    this.setState({
      isAuthenticated: false,
      username: ''
    });

    fetch('./logout.php')
    .then(response => {
      console.log(response);
    })
  }

  render(){
    return (
      <HashRouter>
        {!this.state.isAuthenticated &&
          <div className="loginBackground">
            <div className="loginWrapper">
            <Redirect to="/login" />
              <div className="form">
                  <form className="login-form" action="" method="post" name="loginform">
                      <input
                        type="text"
                        name="email"
                        placeholder="example@email.com"
                        required className="form-control"
                        onChange={this.handleOnChange}
                      />
                      <input
                        type="password"
                        name="password"
                        placeholder="password"
                        required className="form-control"
                        onChange={this.handleOnChange}
                      />
                      <button
                        type="submit"
                        name="login"
                        value="login"
                        onClick={this.login}>
                          login
                      </button>
                  </form>
                  {this.state.loginError &&
                    <span className="errorMessage">{this.state.errorMessage}</span>
                  }
                  <p className="message">Your IP address is: {this.state.ip}</p>
              </div>
            </div>
          </div>
        }
        {this.state.isAuthenticated &&
          <div className="App">
            <Redirect to="/" />
            <nav className="topnav">
              <input className="menu-btn" type="checkbox" id="menu-btn" ref={'nav'} />
              <label className="menu-icon" htmlFor="menu-btn">
                <span className="navicon"></span>
              </label>
              <ul className="menu">
                <li>
                  <NavLink
                    exact
                    to="/"
                    activeClassName="active"
                    onClick={this.closeNav}>
                      Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/dev/"
                    activeClassName="active"
                    onClick={this.closeNav}>
                      Dev Area
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/midas/"
                    activeClassName="active"
                    onClick={this.closeNav}>
                      MIDAS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/docs/"
                    activeClassName="active"
                    onClick={this.closeNav}>
                      Docs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/ukgc/"
                    activeClassName="active"
                    onClick={this.closeNav}>
                      UKGC
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/tools/"
                    activeClassName="active"
                    onClick={this.closeNav}>
                      Tools
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/hr/"
                    activeClassName="active"
                    onClick={this.closeNav}>
                      HR
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/demo/"
                    activeClassName="active"
                    onClick={this.closeNav}>
                      Demo
                  </NavLink>
                </li>
                <li>
                  <a href="https://exchange2019.ionos.co.uk/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2fexchange2019.ionos.co.uk%2fowa%2f">
                      Web Mail
                  </a>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/logout/"
                    activeClassName="active"
                    onClick={this.logOut}>
                      Logout
                  </NavLink>
                </li>
              </ul>
            </nav>

            <footer>
              <p>User: {this.state.username}</p>
              <p>IP: {this.state.ip}</p>
              <p id="date">{this.state.date}</p>
            </footer>
          </div>
        }
       <Switch>
        <Route exact path="/" component={Welcome} />
        <Route exact path="/dev/" render={(props) => (
          <DevArea {...props} userName={this.state.username} />
        )} />
        <Route exact path="/midas/" component={Midas} />
        <Route exact path="/docs/" component={Documentation} />
        <Route exact path="/tools/" component={Tools} />
        <Route exact path="/hr/" component={Hr} />
        <Route exact path="/ukgc/" component={Ukgc} />
       </Switch>
     </HashRouter>
    )
  };
}

export default App;
