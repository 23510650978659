import React, { Component } from 'react';
import './devarea.css';
import {
  ARIS_COOKIE_DESTINATION,
  ARIS_COOKIE_ENDPOINT,
  ARIS_COOKIE_GAME,
  ARIS_COOKIE_USER,
  ARIS_GAMES,
  ARIS_getCookie,
  ARIS_LOCATIONS,
  ARIS_setCookie,
  ARIS_USERS,
  ARIS_SERVICE_OPTIONS
} from './services/ARIS';
import {
  SG_CLOCK,
  SG_CURRENCY,
  SG_DEPOSITURL,
  SG_DEVICE,
  SG_GAMES,
  SG_JURISDICTION,
  SG_LANGUAGES,
  SG_ENV,
  SG_LOBBY,
  SG_NYXROOT,
  SG_OPERATORID,
  SG_SELECTEDOPIDS,
  SG_OPIDS,
  SG_SECURE,
  SG_TYPE,
  SG_getCookie,
  SG_setCookie
} from './services/SG';
import { Comm } from './services/Comm';

class DevArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aris_cookie_endpoint: ARIS_COOKIE_ENDPOINT,
      aris_engine_endpoint: ARIS_getCookie('engineEndPoint') ? ARIS_getCookie('engineEndPoint') : 'https://play16.aristx.net',
      aris_web_endpoint: ARIS_getCookie('webEndPoint') ? ARIS_getCookie('webEndPoint') : 'https://demo-kore16.aristx-external.net/MandarinPalace/en-US/webapps',
      aris_fixed_results: ARIS_getCookie('arisFixed') ? ARIS_getCookie('arisFixed') : 'https://fixedresults-saucify16.aristx.net/Default.aspx',
      aris_test_site: ARIS_getCookie('arisTestSite') ? ARIS_getCookie('arisTestSite') : 'https://demo-kore16.aristx-external.net/MandarinPalace',
      aris_service_index: ARIS_getCookie('arisServiceInd') ? ARIS_getCookie('arisServiceInd') : 4,

      destination: ARIS_getCookie(ARIS_COOKIE_DESTINATION) ? ARIS_getCookie(ARIS_COOKIE_DESTINATION) : 'https://www.crucibledev.net/dev/aris/',
      gameFolder: ARIS_getCookie(ARIS_COOKIE_GAME),
      gameOptions: ARIS_GAMES,
      launchLocation: ARIS_getCookie('launchLocation') ? ARIS_getCookie('launchLocation') : 'AWS',
      launchLocationOptions: ARIS_LOCATIONS,
      loginAs: ARIS_getCookie('loginAs') ? ARIS_getCookie('loginAs') : 'test1@crucible.com',
      message: '',
      safariArisLink: '',
      service: ARIS_getCookie('service') ? ARIS_getCookie('service') : "4, Saucify/Kore 4",
      serviceOptions: ARIS_SERVICE_OPTIONS,
      SGClock: SG_getCookie('clock')?.toLowerCase() !== 'true' ? false : true,
      SGCurrency: SG_getCookie('currency') ? SG_getCookie('currency') : 'British Pound | GBP,GBP',
      SGCurrencyCode: SG_getCookie('sgCurrencyCode') ? SG_getCookie('sgCurrencyCode') : 'GBP',
      SGCurrencyDropdown: SG_CURRENCY,
      SGDepositURL: SG_DEPOSITURL,
      SGDevice: SG_getCookie('device') ? SG_getCookie('device') : 'Desktop',
      SGDeviceDropdown: SG_DEVICE,
      SGGameID: SG_getCookie('sggameid') ? SG_getCookie('sggameid') : 'Select Game',
      SelectedSGGameID: SG_getCookie('SelectedSGGameID') ? SG_getCookie('SelectedSGGameID') : "Select Game,0",
      SGGameIDDropdown: SG_GAMES,
      SGGRUB: SG_getCookie('grub')?.toLowerCase() === 'true' ? true : false, 
      SGHideRTP: SG_getCookie('hidertp')?.toLowerCase() === 'true' ? true : false,
      SGJur: SG_getCookie('sgJur') ? SG_getCookie('sgJur') : '.com',
      SGJurCode: SG_getCookie('sgJurCode') ? SG_getCookie('sgJurCode') : '.com',
      SGJurDropdown: SG_JURISDICTION,
      SGLang: SG_getCookie('sgLang') ? SG_getCookie('sgLang') : 'English | en_gb,en_gb',
      SGLangCode: SG_getCookie('sgLangCode') ? SG_getCookie('sgLangCode') : 'en_gb',
      SGLangDropDown: SG_LANGUAGES,
      SGEnv: SG_getCookie('sgEnv') ? SG_getCookie('sgEnv') : 'Forge',
      SGEnvDropdown: SG_ENV,
      SGLobbyURL: SG_LOBBY,
      SGMode: SG_getCookie('sgMode') ? SG_getCookie('sgMode') : 'demo',
      SGModeDropdown: ['demo', 'real'],
      SGNyxroot: SG_NYXROOT,
      SGOGSGameID: SG_getCookie('ogsID') ? SG_getCookie('ogsID') : '0',
      SelectedSGOPID: SG_getCookie('SelectedSGOPID') ? SG_getCookie('SelectedSGOPID') : SG_SELECTEDOPIDS,
      SelectedSGOPIDObject: SG_getCookie('SelectedSGOPIDObject') ? SG_getCookie('SelectedSGOPIDObject') : SG_SELECTEDOPIDS,
      SGOperatorID: SG_getCookie('SGOPID') ? SG_getCookie('SGOPID') : SG_OPERATORID,
      SGOperatorIDDropdown: SG_OPIDS,
      SGSecure: SG_SECURE,
      SGSessionID: SG_getCookie('SGSessionID') ? SG_getCookie('SGSessionID') : '',
      SGType: SG_TYPE,
      user: ARIS_getCookie(ARIS_COOKIE_USER),
      userName: this.props.userName,
      userOptions: userOptions()
    }
    this.ARIS_getGameId = this.ARIS_getGameId.bind(this);
    this.ARISLaunch = this.ARISLaunch.bind(this);
    this.ARIS_receiveErrorResponse = this.ARIS_receiveErrorResponse.bind(this);
    this.ARIS_receiveLoginResponse = this.ARIS_receiveLoginResponse.bind(this);
    this.getGamePathDev = this.getGamePathDev.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleLaunchOnChange = this.handleLaunchOnChange.bind(this);
    this.handleUserOnChange = this.handleUserOnChange.bind(this);
    this.serviceChange = this.serviceChange.bind(this);
    this.handleOnChangeSGGame = this.handleOnChangeSGGame.bind(this);
    this.handleOnChangeSGlang = this.handleOnChangeSGlang.bind(this);
    this.handleOnChangeSGOPID = this.handleOnChangeSGOPID.bind(this);
    this.SGLaunch = this.SGLaunch.bind(this);

    function userOptions() {
      let users = ARIS_USERS;
      var more = users.length + 1;
      for (var u = more; u < 17; u++) {
        users.push("test" + u);
      };

      if (!users.includes('Bonus')) {
        users.push('Bonus');
      }

      return users;
    }
  }

  componentDidMount() {
    const app = this;

    let currentUser = app.props.userName;

    let users = app.state.userOptions;
    let totalUsers = users.length;
    let i = 0;
    for (i = 0; i < totalUsers; i++) {
      if (currentUser.indexOf(users[i]) > -1) {
        console.log(`Current User is ${users[i]}`);
        let userInd = i + 1;
        console.log(`Current User Index is ${userInd}`);
        app.setState({
          user: users[i],
          loginAs: "test" + userInd + '@crucible.com'
        })
      }
    }

  }

  ARISLaunch() {
    if (this.state.gameFolder === "Select Game") {
      return alert('Please select a game, you muppet!');
    }

    ARIS_setCookie(ARIS_COOKIE_DESTINATION, this.state.destination, 365);
    ARIS_setCookie(ARIS_COOKIE_GAME, this.state.gameFolder, 365);
    ARIS_setCookie(ARIS_COOKIE_USER, this.state.user, 365);
    ARIS_setCookie('loginAs', this.state.loginAs, 365);
    ARIS_setCookie('launchLocation', this.state.launchLocation, 365);
    ARIS_setCookie('engineEndPoint', this.state.aris_engine_endpoint, 365);
    ARIS_setCookie('webEndPoint', this.state.aris_web_endpoint, 365);
    ARIS_setCookie('arisServiceInd', this.state.aris_service_index, 365);
    ARIS_setCookie('arisFixed', this.state.aris_fixed_results, 365);
    ARIS_setCookie('arisTestSite', this.state.aris_test_site, 365);
    ARIS_setCookie('service', this.state.service, 365);

    const comms = new Comm();
    const server = this.state.aris_engine_endpoint + '/Services/UserService/Login';

    // IMPORTANT
    // Login Request to Aris to get credentials
    let user = '';
    let password = 'test';
    if (this.state.user === 'Bonus') {
      user = 'crucible-bonus@aris.com';
    } else if(this.state.loginAs === 'test18@crucible.com' || this.state.loginAs === 'test19@crucible.com' || this.state.loginAs === 'test20@crucible.com' || this.state.loginAs === 'test21@crucible.com' || this.state.loginAs === 'test22@crucible.com' || this.state.loginAs === 'test23@crucible.com'){
      user = this.state.loginAs;
      password = 'test1234';
    } else {
      user = this.state.loginAs;
    }
    const login_req = '{"email":"' + user + '","password":"' + password + '","brand": "MandarinPalace","siteLocale":"en-US"}';

    comms.doPost(server, login_req, this.ARIS_receiveLoginResponse, this.ARIS_receiveErrorResponse);
  }

  ARIS_receiveLoginResponse(response) {
    var jsonResponse = JSON.parse(response);
    this.setState({
      message: 'Response:' + response
    })

    // Generic login string FOR MandarinPalace-webclient-saucify7 (Kore7), but we must replace the session id and user token with a genuine current one.
    var unencodedQuery = window.atob('eyJicmFuZCI6Ik1hbmRhcmluUGFsYWNlIiwibG9jYWxlIjoiZW4tVVMiLCJnYW1lX2lkIjoiQmVpamluZ05pZ2h0c1ZpZGVvU2xvdCIsImdhbWVfbmFtZSI6IkJlaWppbmdOaWdodHNWaWRlb1Nsb3QiLCJjdXJyZW5jeSI6IlVTRCIsInVzZXJfdG9rZW4iOiIyV0xGUW11Si9JMm9tMkhJRkVoZk04Zzc5MEFkSkE9PSIsInNlc3Npb25faWQiOiJOU3F1TDhmaXFYcWxRMHk2MlNPNCtXMk42UUxMNUUxb2ZQYTYyVzNqalVrPSIsInBsYXltb2RlIjoiUmVhbCIsInNlcnZpY2VfdXJsIjoiaHR0cHM6Ly9wbGF5MTYuYXJpc3R4Lm5ldC9zZXJ2aWNlcyIsIndlYmFwcF91cmwiOiJodHRwczovL3BsYXkxNi5hcmlzdHgubmV0L01hbmRhcmluUGFsYWNlL2VuLVVTL3dlYmFwcHMiLCJyZXR1cm5fdXJsIjoiaHR0cHM6Ly9kZW1vLWtvcmUxNi5hcmlzdHgtZXh0ZXJuYWwubmV0L01hbmRhcmluUGFsYWNlIn0');

    let jsonQuery = JSON.parse(unencodedQuery);
    /*
     {
           "brand": "MandarinPalace",
           "locale": "en-US",
           "game_id": "BeijingNightsVideoSlot",        // Alter this to get engine for a different game (if engine for yours not written yet)
           "game_name": "BeijingNightsVideoSlot",      // Alter this to redirect into the game we actually want
           "currency": "USD",
           "user_token": "2WLFQmuJ/I2om2HIFEhfM8g790AdJA==",
           "session_id": "NSquL8fiqXqlQ0y62SO4+W2N6QLL5E1ofPa62W3jjUk=",
           "playmode": "Real",
           "service_url": "https://play16.aristx.net/services",
           "webapp_url": "https://play16.aristx.net/MandarinPalace/en-US/webapps",
           "return_url": "https://demo-kore16.aristx-external.net/MandarinPalace"
       }
    */

    jsonQuery.session_id = jsonResponse.Credentials.SessionID;
    jsonQuery.user_token = jsonResponse.Credentials.UserToken;

    const gameFolder = this.state.gameFolder;

    // Direct user into the game folder they asked for
    jsonQuery.game_name = gameFolder + "VideoSlot";

    // Direct user into the engine they asked for
    jsonQuery.game_id = this.ARIS_getGameId(jsonQuery.game_name);

    // Point to the correct endpoints
    jsonQuery.service_url = this.state.aris_engine_endpoint + "/services";
    jsonQuery.webapp_url = this.state.aris_web_endpoint;
    jsonQuery.return_url = this.state.aris_test_site;

    // Re-encode query string with new (genuine) data
    var encodedQuery = window.btoa(JSON.stringify(jsonQuery));
    const destination = this.state.destination;

    const gamePath = '?token=';

    // Go
    var url = destination + gamePath + encodedQuery;

    this.setState({
      safariArisLink: url
    })
    // window.open(url, '_self');
    window.open(url, '_blank').focus();
  }


  /**
   * Provides an alternative engine for some games
   * @param {game} gameName
   */
  ARIS_getGameId(gameName) {
    const engineReplacements = {
      "JokerHot7sVideoSlot": "BrightStarVideoSlot",
      "BookOf7sVideoSlot": "VegasTriplePayDeluxeVideoSlot",
      "AnvilTemplateVideoSlot": "VegasTriplePayDeluxeVideoSlot"
    }

    return engineReplacements[gameName] ? engineReplacements[gameName] : gameName;
  }

  ARIS_receiveErrorResponse(response) {
    console.error(response);
    this.setState({
      message: "An error has occurred."
    });
  }

  handleOnChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeCheckbox(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleLaunchOnChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    let dest = e.target.value;
    switch (dest) {
      case 'AWS':
        this.setState({
          destination: 'https://www.crucibledev.net/dev/aris/'
        });
        break;
      case 'Stage':
        this.setState({
          destination: 'https://stage.versagaming.net'
        });
        break;
      case 'Prod':
        this.setState({
          destination: 'https://play.versagaming.net'
        });
        break;
      default:
        this.setState({
          destination: 'https://www.crucibledev.net/dev/aris/'
        });
        break;
    }
  }

  getGamePathDev() {
    return window.location.origin = window.location.origin.includes('localhost')
      ? window.location.origin + '?token='
      : window.location.origin + '/cloudfront/?token=';
  };

  handleUserOnChange(e) {
    let userInd = e.target.selectedIndex + 1;
    this.setState({
      [e.target.name]: e.target.value,
      loginAs: "test" + userInd + '@crucible.com'
    });
  }

  serviceChange(e) {
    let serviceInfo = e.target.value.split(",");
    let serviceInd = serviceInfo[0];

    this.setState({
      aris_engine_endpoint: "https://play" + serviceInd + ".aristx.net",
      aris_web_endpoint: "https://play" + serviceInd + ".aristx.net/MandarinPalace/en-US/webapps",
      aris_fixed_results: "http://fixedresults-saucify" + serviceInd + ".aristx.net",
      aris_test_site: "https://demo-kore" + serviceInd + ".aristx-external.net/MandarinPalace",
      aris_service_index: serviceInd
    })
    this.setState({ [e.target.name]: e.target.value });
  }

  handleOnChangeSGlang(e) {
    console.log([e.target.name]);
    let langCode = e.target.value.split(",");
    let langState = e.target.name + 'Code';

    console.log(langState);
    console.log(langCode);
    this.setState({ [e.target.name]: e.target.value });
    if (langState === "SGCurrencyCode") {
      this.setState({ SGCurrencyCode: langCode[1] });
    } else if (langState === "SGJurCode") {
      this.setState({ SGJurCode: langCode[1] });
    } else {
      this.setState({ SGLangCode: langCode[1] });
    }
  }

  handleOnChangeSGGame(e) {
    console.log([e.target.name]);
    let gameInfo = e.target.value.split(",");
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ SGGameID: gameInfo[0] });
    console.log(gameInfo);
    this.setState({ SGOGSGameID: gameInfo[1] });
  }

  handleOnChangeSGOPID(e) {
    console.log([e.target.name]);
    let SGOPIDArray = e.target.value.split(",");
    this.setState({ [e.target.name]: SGOPIDArray[0] });
    this.setState({ SelectedSGOPID: e.target.value });
    this.setState({ SelectedSGOPIDObject: { id: SGOPIDArray[0], label: SGOPIDArray[1] } });
  }

  SGLaunch() {
    if (this.state.SGOGSGameID === "0") {
      return alert("Either you didn't choose a game, like a muppet. Or Briggy broke the SG launcher. Contact him with your error.")
    }

    SG_setCookie('currency', this.state.SGCurrency, 365);
    SG_setCookie('sgCurrencyCode', this.state.SGCurrencyCode, 365);
    SG_setCookie('device', this.state.SGDevice, 365);
    SG_setCookie('sggameid', this.state.SGGameID, 365);
    SG_setCookie('SGOPID', this.state.SGOperatorID, 365);
    SG_setCookie('sgLang', this.state.SGLang, 365);
    SG_setCookie('sgLangCode', this.state.SGLangCode, 365);
    SG_setCookie('sgMode', this.state.SGMode, 365);
    SG_setCookie('ogsID', this.state.SGOGSGameID, 365);
    SG_setCookie('SGSessionID', this.state.SGSessionID, 365);
    SG_setCookie('SelectedSGGameID', `${this.state.SGGameID},${this.state.SGOGSGameID}`, 365);
    SG_setCookie('sgEnv', `${this.state.SGEnv}`, 365);
    SG_setCookie('SelectedSGOPID', this.state.SelectedSGOPID, 365);
    SG_setCookie('SelectedSGOPIDObject', this.state.SelectedSGOPIDObject, 365);
    SG_setCookie('hidertp', this.state.SGHideRTP, 365);
    SG_setCookie('clock', this.state.SGClock, 365);
    SG_setCookie('grub', this.state.SGGRUB, 365);
    SG_setCookie('sgJur', this.state.SGJur, 365);
    SG_setCookie('sgJurCode', this.state.SGJurCode, 365);

    let domainMobile;
    let domainDesktop;
    if (this.state.SGEnv === 'Forge') {
      //domainMobile = "https://crucibledev.net/dev/sg/gcmwrapper.html?envid=eur&dev=1&configurl=https://ogs-gcm-eu-dev.nyxop.net/gcm/gcm-core/gcm.js&";
      domainMobile = "https://ogs-gcm-eu-dev.nyxop.net/gcm/gcm-launcher/launcher.html?gameUrl=https%3A%2F%2Fcrucibledev.net%2Fdev%2Fsg%2Fgcmwrapper.html%3Fenvid%3Deur%26dev%3D1%26configurl%3Dhttps%3A%2F%2Fogs-gcm-eu-dev.nyxop.net%2Fgcm%2Fgcm-core%2Fgcm.js&";
      domainDesktop = "https://ogs-gcm-eu-dev.nyxop.net/gcm/gcm-launcher/launcher.html?gameUrl=https%3A%2F%2Fcrucibledev.net%2Fdev%2Fsg%2Fgcmwrapper.html%3Fenvid%3Deur%26dev%3D1%26configurl%3Dhttps%3A%2F%2Fogs-gcm-eu-dev.nyxop.net%2Fgcm%2Fgcm-core%2Fgcm.js&";
    }
    if (this.state.SGEnv === 'Stage') {
      domainMobile = "https://ogs-gcm-eu-dev.nyxop.net/gcm/gcm-launcher/launcher.html?gameUrl=https%3A%2F%2Fnyx-ogs-2pp-int.s3.amazonaws.com%2Fhtml5%2Fgcmwrapper.html%3Fenvid%3Deur%26dev%3D1%26configurl%3Dhttps%3A%2F%2Fogs-gcm-eu-dev.nyxop.net%2Fgcm%2Fgcm-core%2Fgcm.js&";
      domainDesktop = "https://ogs-gcm-eu-dev.nyxop.net/gcm/gcm-launcher/launcher.html?gameUrl=https%3A%2F%2Fnyx-ogs-2pp-int.s3.amazonaws.com%2Fhtml5%2Fgcmwrapper.html%3Fenvid%3Deur%26dev%3D1%26configurl%3Dhttps%3A%2F%2Fogs-gcm-eu-dev.nyxop.net%2Fgcm%2Fgcm-core%2Fgcm.js&";
    }
    let hideRTP = '';
    let gameid = `gameid=${this.state.SGGameID}`;
    let sessionid;
    if(this.state.SGSessionID === ''){
      sessionid = `&operatorid=${this.state.SGOperatorID}&sessionid=Free%3A${this.state.user}&`;
    } else {
      sessionid = `&operatorid=${this.state.SGOperatorID}&sessionid=${this.state.SGSessionID}&`;
    }
    let currency = `currency=${this.state.SGCurrencyCode}`;
    let lang = `&lang=${this.state.SGLangCode}`;
    let mode = `&mode=${this.state.SGMode}`;
    let secure = `&secure=${this.state.SGSecure}`;
    let type = `&type=${this.state.SGType}`;
    let clock = `&clock=${this.state.SGClock}`;
    let device = `&device=${this.state.SGDevice.toLowerCase()}`;
    let lobbyURL;
    if(this.state.SGGRUB){
      lobbyURL = `&lobbyurl=https%3A%2F%2Fgrub.nyxaws.net`; 
    } else {
      lobbyURL = `&lobbyurl=${this.state.SGLobbyURL}`;
    }
    let depositURL = `&depositurl=${this.state.SGDepositURL}`;
    let ogsgameid = `&ogsgameid=${this.state.SGOGSGameID}`;
    let nyxroot = `&nyxroot=${this.state.SGNyxroot}`;
    let jurisdiction = `&jurisdiction=${this.state.SGJurCode}`;
    if (this.state.SGHideRTP) {
      hideRTP = `&hidertp=${this.state.SGHideRTP}`;
    } else {
      hideRTP = '';
    }
    let domain = this.state.SGDevice === 'Desktop' ? domainDesktop : domainMobile;
    let GRUB = this.state.SGGRUB ? `&forcedOutcome=https%3A%2F%2Fcrucibledev.net%2Fdev%2Fsg%2F${this.state.SGGameID}_forces.csv` : '';

    var url = domain + gameid + sessionid + currency + lang + mode + secure + type + clock + device + lobbyURL + depositURL + ogsgameid + nyxroot + jurisdiction + hideRTP + GRUB;

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    if (isSafari) {
      // window.open(url, '_self');
      window.open(url);
    } else {
      // window.open(url, '_self');
      window.open(url, '_blank').focus();
    }
  }

  render() {
    return (
      <div className="componentContainer">
        <h1>Dev Area</h1>
        <div className="dev-header">
          <h3>Kore Launcher</h3>
        </div>
        <div className="dev-container">
          <div className="dev-item">
            Log In As:
            <br />
            <select
              id="useraccount"
              name="user"
              onChange={this.handleUserOnChange}
              value={this.state.user}>
              {
                this.state.userOptions.map((option, index) =>
                  <option key={index} value={option}>{option}</option>
                )}
            </select>
            <div id="loginas"></div>
          </div>
          <div className="dev-item">
            Service ID:
            <br />
            <select
              id="service"
              name="service"
              onChange={this.serviceChange}
              value={this.state.service}>
              {
                this.state.serviceOptions.map((option, index) =>
                  <option key={index} value={[option.id, option.label]}>{option.label}</option>
                )
              }
            </select>
          </div>
          <div className="dev-item">
            Game:
            <br />
            <select
              id="gamefolder"
              name="gameFolder"
              onChange={this.handleOnChange}
              value={this.state.gameFolder}>
              {
                this.state.gameOptions.map((option, index) =>
                  <option key={index} value={option}>{option}</option>)
              }
            </select>
          </div>
          <div className="dev-item">
            Environment:
            <br />
            <select
              id="launchLoc"
              name="launchLocation"
              onChange={this.handleLaunchOnChange}
              value={this.state.launchLocation}>
              {
                this.state.launchLocationOptions.map((option, index) =>
                  <option key={index} value={option} destination={index}>{option}</option>)
              }
            </select>
          </div>
          <div className="dev-item">
            Launch!
            <br />
            <input id="gamePath" className="gamepath" size="30" value={this.state.destination} readOnly />
            <button
              id="goCloud"
              onClick={this.ARISLaunch}>
              GO!
            </button>
            <br />
            {this.state.safariArisLink &&
              <a href={this.state.safariArisLink} target="_blank" rel="noopener noreferrer">Safari Launcher Backup</a>
            }
          </div>
        </div>

        <div className="dev-header">
          <h3>ARIS External Links</h3>
        </div>
        <div className="dev-container">
          <div className="external-item">
            <a href={this.state.aris_test_site} target="blank">Aris Kore {this.state.aris_service_index} External Test Site</a>
          </div>
          <div className="external-item">
            <a href={this.state.aris_fixed_results} target="blank">Fixed Results {this.state.aris_service_index} External Test Site</a>
          </div>
          <div className="external-item">
            <a href="https://godtest-saucify21.aristx.net/" target="blank">Games on Demand 21 External Test Site</a>
          </div>
          <div className="external-item">
            <a href="https://godfixedresults-saucify21.aristx.net/Default.aspx" target="blank">GOD Fixed Results 21 External Test Site</a>
          </div>
        </div>

        <div className="dev-header">
          <h3>L&amp;W Launcher</h3>
        </div>
        <div className="dev-container">
          <div className="dev-item">
            Log In As:
            <br />
            <select
              id="useraccount"
              name="user"
              onChange={this.handleOnChange}
              value={this.state.user}>
              {
                this.state.userOptions.map((option, index) =>
                  <option key={index} value={option}>{option}</option>
                )}
            </select>
            <div id="loginas"></div>
          </div>

          <div className="dev-item">
            Game:
            <br />
            <select
              id="SelectedSGGameID"
              name="SelectedSGGameID"
              onChange={this.handleOnChangeSGGame}
              value={this.state.SelectedSGGameID}>
              {
                this.state.SGGameIDDropdown.map(option =>
                  <option key={option.OGSid} value={[option.name, option.OGSid]}>{option.name}</option>)
              }
            </select>
          </div>
          <div className="dev-item">
            Currency:
            <br />
            <select
              id="SGCurrency"
              name="SGCurrency"
              onChange={this.handleOnChangeSGlang}
              value={this.state.SGCurrency}>
              {
                this.state.SGCurrencyDropdown.map((option, index) =>
                  <option key={index} value={[option.name, option.code]} destination={index}>{option.name}</option>)
              }
            </select>
          </div>
          <div className="dev-item">
            Language:
            <br />
            <select
              id="SGLang"
              name="SGLang"
              onChange={this.handleOnChangeSGlang}
              value={this.state.SGLang}>
              {
                this.state.SGLangDropDown.map((option, index) =>
                  <option key={index} value={[option.name, option.code]} destination={index}>{option.name}</option>)
              }
            </select>
          </div>
          <div className="dev-item">
            OPID:
            <br />
            <select
              id="SGOperatorID"
              name="SGOperatorID"
              onChange={this.handleOnChangeSGOPID}
              value={this.state.SelectedSGOPID}>
              {
                this.state.SGOperatorIDDropdown.map((option, index) =>
                  <option key={index} value={[option.id, option.label]} destination={index}>{option.label}</option>)
              }
            </select>
          </div>
          <div className="dev-item">
            SessionID:
            <br />
            <input
              id="SGSessionID"
              name="SGSessionID"
              onChange={this.handleOnChange}
              value={this.state.SGSessionID}
              />
          </div>
          <div className="dev-item">
            Mode:
            <br />
            <select
              id="SGMode"
              name="SGMode"
              onChange={this.handleOnChange}
              value={this.state.SGMode}>
              {
                this.state.SGModeDropdown.map((option, index) =>
                  <option key={index} value={option} destination={index}>{option}</option>)
              }
            </select>
          </div>
          <div className="dev-item">
            Device:
            <br />
            <select
              id="SGDevice"
              name="SGDevice"
              onChange={this.handleOnChange}
              value={this.state.SGDevice}>
              {
                this.state.SGDeviceDropdown.map((option, index) =>
                  <option key={index} value={option} destination={index}>{option}</option>)
              }
            </select>
          </div>
          <div className="dev-item">
            Launch Env:
            <br />
            <select
              id="SGEnv"
              name="SGEnv"
              onChange={this.handleOnChange}
              value={this.state.SGEnv}>
              {
                this.state.SGEnvDropdown.map((option, index) =>
                  <option key={index} value={option} destination={index}>{option}</option>)
              }
            </select>
          </div>
          <div className='dev-item'>
            Jurisdiction:
            <br />
            <select
              id="SGJur"
              name="SGJur"
              onChange={this.handleOnChangeSGlang}
              value={this.state.SGJur}>
              {
                this.state.SGJurDropdown.map((option, index) =>
                  <option key={index} value={[option.name, option.code]} destination={index}>{option.name}</option>)
              }
            </select>
          </div>
          <div className="dev-item">
            Hide RTP:
            <br />
            <input
              id="SGHideRTP"
              name="SGHideRTP"
              type="checkbox"
              defaultChecked={this.state.SGHideRTP}
              onChange={this.handleChangeCheckbox}
            />
          </div>
          <div className="dev-item">
            Clock:
            <br />
            <input
              id="SGClock"
              name="SGClock"
              type="checkbox"
              defaultChecked={this.state.SGClock}
              onChange={this.handleChangeCheckbox}
            />
          </div>
          <div className="dev-item" title="GRUB only works in Real Play - SessionID is required">
            GRUB:
            <br />
            <input
              id="SGGRUB"
              name="SGGRUB"
              type="checkbox"
              defaultChecked={this.state.SGGRUB}
              onChange={this.handleChangeCheckbox}
            />
          </div>
          <div className="dev-item">
            Launch!<br></br>
            <button
              id="goSG"
              onClick={this.SGLaunch}>
              GO!
            </button>
          </div>
        </div>

        <div className="dev-header">
          <h3>L&amp;W External Links</h3>
        </div>
        <div className="dev-container">
          <div className="external-item">
            <a href="https://ogs-gut-gdk-cruciblesoftware.nyxaws.net:3333/" target="blank">L&amp;W Game Upload Tool</a>
          </div>
          <div className="external-item">
            <a href="https://ogs-gdm-gdk-cruciblesoftware.nyxaws.net/forcetool" target="blank">L&amp;W Force Tool</a>
          </div>
          <div className="external-item">
            <a href="https://ogs-gcm-eu-dev.nyxop.net/gcm-lobby/launch.html" target="blank">OGS Game Launcher</a>
          </div>
          <div className="external-item">
            <a href="https://ogs-bo-gdk-cruciblesoftware.nyxaws.net/" target="blank">Crucible Back Office</a>
          </div>
          <div className="external-item">
            <a href="https://ogs-bo-aws-2pp-int-generic-0.nyxaws.net/" target="blank">2PP Generic Back Office - (nyx / nogs)</a>
          </div>
          <div className="external-item">
            <a href="https://ogs-gdm-aws-2pp-int-generic-0.nyxaws.net/forcetool" target="blank">2PP Generic Force Tool</a>
          </div>
          <div className="external-item">
            <a href="https://ogs-mock-gdk-cruciblesoftware.nyxaws.net/admin/" target="blank">Crucible Mock Wallet</a>
          </div>
          <div className="external-item">
            <a href="https://ogs-accountmock-0.nyxaws.net/admin/login/?next=/admin/" target="blank">2PP Generic Mock Wallet</a>
          </div>
        </div>
      </div>
    )
  }
}

export default DevArea;
