import React, { Component } from 'react';

class Ukgc extends Component {
  render(){
    return (
      <div className="componentContainer">
        <h1>UKGC</h1>
        
        <div className="componentContainer">
          <h2>Documents</h2>
          <div className="grid-container">
            <div className="grid-item">
              <a href="/ukgc/Security-audit-advice.pdf" target="_blank">
                Security Audit Advice (PDF)
              </a>
            </div>
          </div>
          <div className="grid-container">
            <div className="grid-item">
              <a href="/ukgc/Remote-gambling-and-software-technical-standards.pdf" target="_blank">
                Remote Gambling and Software Technical Standards (PDF)
              </a>
            </div>
          </div>
        </div>
        
        <div className="componentContainer">
          <h2>Licence conditions/Reminders/Etc coming Soon:</h2>
          <div className="grid-container">
            <div className="grid-item">
              Not Available
            </div>
          </div>
          <div className="grid-container">
            <div className="grid-item">
              Not Available
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ukgc;
