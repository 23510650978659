/**
* SG LAUNCHER STUFF
*/
export var SG_CLOCK = 'true';
export var SG_CURRENCY = [
  {name: 'Armenian Dram | AMD', code: 'AMD'},
  {name: 'Argentina Peso | ARS', code: 'ARS'},
  {name: 'Australian Dollar | AUD', code: 'AUD'},
  {name: 'Bulgarian Lev | BGN', code: 'BGN'},
  {name: 'Burundi Franc | BIF', code: 'BIF'},
  {name: 'Brazilian Real | BRL', code: 'BRL'},
  {name: 'Canadian Dollar | CAD', code: 'CAD'},
  {name: 'Congolese Franc | CDF', code: 'CDF'},
  {name: 'Swiss Franc | CHF', code: 'CHF'},
  {name: 'Chinese Yuan Ren. | CNY', code: 'CNY'},
  {name: 'Colombian Peso | COP', code: 'COP'},
  {name: 'Czech Koruna | CZK', code: 'CZK'},
  {name: 'Danish Krone | DKK', code: 'DKK'},
  {name: 'Euro | EUR', code: 'EUR'},
  {name: 'British Pound | GBP', code: 'GBP'},
  {name: 'Georgian Lari | GEL', code: 'GEL'},
  {name: 'Hong Kong Dollar | HKD', code: 'HKD'},
  {name: 'Croatian Kuna | HRK', code: 'HRK'},
  {name: 'Hungarian Forint | HUF', code: 'HUF'},
  {name: 'Indonesia Rupiah | IDR', code: 'IDR'},
  {name: 'Israel New Shekel| ILS', code: 'ILS'},
  {name: 'Indian Rupee | INR', code: 'INR'},
  {name: 'Iceland Krona | ISK', code: 'ISK'},
  {name: 'Japanese Yen | JPY', code: 'JPY'},
  {name: 'Cambodian Riel | KHR', code: 'KHR'},
  {name: 'Korea (South) Won | KRW', code: 'KRW'},
  {name: 'Moroccan Dirham | MAD', code: 'MAD'},
  {name: 'Myanmar Kyat | MMK', code: 'MMK'},
  {name: 'Mexico Peso | MXN', code: 'MXN'},
  {name: 'Malaysia Ringgit | MYR', code: 'MYR'},
  {name: 'Nigerian Naira | NGN', code: 'NGN'},
  {name: 'Norwegian Krone | NOK', code: 'NOK'},
  {name: 'New Zealand Dollar | NZD', code: 'NZD'},
  {name: 'Peru - Nuevo Sol | PEN', code: 'PEN'},
  {name: 'Philippines Peso | PHP', code: 'PHP'},
  {name: 'Polish Zloty | PLN', code: 'PLN'},
  {name: 'Romania - New Leu | RON', code: 'RON'},
  {name: 'Serbian Dinar | RSD', code: 'RSD'},
  {name: 'Russian Ruble | RUB', code: 'RUB'},
  {name: 'Swedish Krona | SEK', code: 'SEK'},
  {name: 'Singapore Dollar | SGD', code: 'SGD'},
  {name: 'Thailand Baht | THB', code: 'THB'},
  {name: 'Turkish Lira | TRY', code: 'TRY'},
  {name: 'Taiwan New Dollar | TWD', code: 'TWD'},
  {name: 'Ukraine Hryvnia | UAH', code: 'UAH'},
  {name: 'US Dollar | USD', code: 'USD'},
  {name: 'Uruguan Peso | UYU', code: 'UYU'},
  {name: 'Venezuelan Bol. Fuerte | VEF', code: 'VEF'},
  {name: 'Vietnamese Dong | VND', code: 'VND'},
  {name: 'Cent. Afri. CFA Franc | XAF', code: 'XAF'},
  {name: 'West African CFA Franc | XOF', code: 'XOF'},
  {name: 'South African Rand | ZAR', code: 'ZAR'},
  {name: 'Zambian Kwacha | ZMW', code: 'ZMW'}
];
export var SG_DEPOSITURL = '';
export var SG_DEVICE = ['Mobile', 'Desktop'];
export var SG_GAMES = [
    {name: "Select Game", OGSid: "0"},
    {name: "actionbankcashshot", OGSid: "70710"},
    {name: "barxinfinityreels", OGSid: "70552"},
    {name: "beaverlasvegas", OGSid: "70552"},
    {name: "blackknightevolution", OGSid: "70552"},
    {name: "blazinhot7sstackemup", OGSid: "70550"},
    {name: "blazinhot7sbigbonus", OGSid: "70552"},
    {name: "blazinhot7sbiggerbonus", OGSid: "70552"},
    {name: "bookoffusion", OGSid: "70719"},
    {name: "cherryeight", OGSid: "70552"},
    {name: "cherryeight96", OGSid: "70552"},
    {name: "doubleplayer", OGSid: "70552"},
    {name: "dynamitechickens", OGSid: "70552"},
    {name: "eightyeightfortunesdice", OGSid: "70552"},
    {name: "flamingtomatoescashshot", OGSid: "70552"},
    {name: "fivebythree", OGSid: "70552"},
    {name: "grandplayer", OGSid: "70552"},
    {name: "grandplayer96", OGSid: "70552"},
    {name: "hellofadeal", OGSid: "70552"},
    {name: "hurricanehorsecoincombo", OGSid: "70552"},
    {name: "incatreasureshotdrops", OGSid: "70552"},
    {name: "keytosuccess", OGSid: "70676"},
    {name: "kingstimer", OGSid: "70552"},
    {name: "longbeardschestminwin", OGSid: "70552"},
    {name: "luckyrabbitkeno", OGSid: "70552"},
    {name: "oldtimer", OGSid: "70552"},
    {name: "monstercatch", OGSid: "70552"},
    {name: "monopolysuperwheelbonus96", OGSid: "71444"},
    {name: "monopolysuperwheelbonus", OGSid: "70552"},
    {name: "perfectpotionsmegaways", OGSid:"70551"},
    {name: "potsoluckkeno", OGSid:"70551"},
    {name: "pyramidsofluxor", OGSid: "70552"},
    {name: "quarterbackfieldsofglory", OGSid: "70552"},
    {name: "rainbowricheslocomotion", OGSid: "70552"},
    {name: "randomplayer", OGSid: "70552"},
    {name: "reeleminabitfishy", OGSid: "70552"},
    {name: "reelemintournamentfishing", OGSid: "70552"},
    {name: "reignofthemountainking", OGSid: "70552"},
    {name: "roadtripsixtysix", OGSid: "70552"},
    {name: "secretsofmemnon96", OGSid: "70552" },
    {name: "stashandgrablockeduplinks", OGSid: "70552"},
    {name: "summervibescashshot", OGSid: "70552"},
    {name: "superspin", OGSid: "70552"},
    {name: "terrifictigercoincombo", OGSid: "70552"},
    {name: "torque", OGSid: "70552"},
    {name: "twinplayer", OGSid: "70552"},
    {name: "ultrablazingfirelink", OGSid: "70553"},
    {name: "vegasgdm", OGSid: "70552"},
    {name: "wildfever90", OGSid: "70552"},
    {name: "yukongold", OGSid: "70552"},
];
export var SG_LANGUAGES = [
  {name: 'Azerbaijani | az_az', code: 'az_az'},
  {name: 'Basque | eu_es', code: 'eu_es'},
  {name: 'Bulgarian | bg_bg', code: 'bg_bg'},
  {name: 'Catalan | ca_ca', code: 'ca_ca'},
  {name: 'Chinese (Simp.) | zh_cn', code: 'zh_cn'},
  {name: 'Chinese (Trad.) | zh_tw', code: 'zh_tw'},
  {name: 'Croatian | hr_hr', code: 'hr_hr'},
  {name: 'Czech | cs_cz', code: 'cs_cz'},
  {name: 'Danish | da_dk', code: 'da_dk'},
  {name: 'Dutch | nl_nl', code: 'nl_nl'},
  {name: 'English | en_gb', code: 'en_gb'},
  {name: 'English | en_us', code: 'en_us'},
  {name: 'Estonian | et_ee', code: 'et_ee'},
  {name: 'Finnish | fi_sf', code: 'fi_sf'},
  {name: 'French | fr_fr', code: 'fr_fr'},
  {name: 'Canadian French | fr_ca', code: 'fr_ca'},
  {name: 'Georgian | ka_ge', code: 'ka_ge'},
  {name: 'German | de_de', code: 'de_de'},
  {name: 'Greek | el_gr', code: 'el_gr'},
  {name: 'Hebrew | he_il', code: 'he_il'},
  {name: 'Hungarian | hu_hu', code: 'hu_hu'},
  {name: 'Italian | it_it', code: 'it_it'},
  {name: 'Japanese | ja_jp', code: 'ja_jp'},
  {name: 'Korean | ko_kr', code: 'ko_kr'},
  {name: 'Latvian | lv_lv', code: 'lv_lv'},
  {name: 'Lithuanian | lt_lt', code: 'lt_lt'},
  {name: 'Norwegian | no_no', code: 'no_no'},
  {name: 'Polish | pl_pl', code: 'pl_pl'},
  {name: 'Portuguese | pt_pt', code: 'pt_pt'},
  {name: 'Portuguese Brazilian | pt_br', code: 'pt_br'},
  {name: 'Romanian | ro_ro', code: 'ro_ro'},
  {name: 'Russian | ru_ru', code: 'ru_ru'},
  {name: 'Slovakian | sk_sk', code: 'sk_sk'},
  {name: 'Slovenian | sl_sl', code: 'sl_sl'},
  {name: 'Spanish | es_es', code: 'es_es'},
  {name: 'Swedish | sv_se', code: 'sv_se'},
  {name: 'Thai | th_th', code: 'th_th'},
  {name: 'Turkish | tr_tr', code: 'tr_tr'},
  {name: 'Ukranian | uk_ua', code: 'uk_ua'},
  {name: 'Uzbekistani | uz_uz', code: 'uz_uz'},
  {name: 'Vietnamese | vi_vn', code: 'vi_vn'}
];
export var SG_ENV = ['Forge', 'Stage'];
export var SG_LOBBY = 'https://ogs-gcm-eu-dev.nyxop.net/gcm-lobby/launch.html';
export var SG_NYXROOT = 'ogs-gdm-gdk-cruciblesoftware.nyxaws.net/nextgen';
export var SG_OPERATORID = '5';
export var SG_SELECTEDOPIDS = {id: 5, label: "5: UK TEST"};
export var SG_OPIDS = [
  {id: 5, label: "5: UK TEST"},
  {id: 16, label: "16: SG NO GAM/3S/NO QS"},
  {id: 113, label: "113: Denmark 3S/NO QS"},
  {id: 170, label: "170: Germany 5S/NO QS"},
  {id: 975, label: "975: Canada 3S/NO QS"},
  {id: 939, label: "939: Sweden 3S/NO QS"},
  {id: 1178, label: "1178: NewJersey NO GAM"},
  {id: 1285, label: "1285: Norway 3S/NO QS"},
  {id: 1286, label: "1286: Spain 3S/NO QS"}
];
export var SG_SECURE = 'true';
export var SG_TYPE = 'nextgen';
export var SG_JURISDICTION = [
  {name: '.com', code: '.com'},
  {name: 'ALC', code: 'ca-alc'},
  {name: 'Alderney', code: 'gg'},
  {name: 'BCLC', code: 'ca-bclc'},
  {name: 'Belgium', code: 'be'},
  {name: 'Bulgaria', code: 'bg'},
  {name: 'Colombia', code: 'co'},
  {name: 'Croatia', code: 'hr'},
  {name: 'Czech', code: 'cz'},
  {name: 'Delaware', code: 'us-de'},
  {name: 'Denmark', code: 'dk'},
  {name: 'Estonia', code: 'ee'},
  {name: 'Germany', code: 'de'},
  {name: 'Gibraltar', code: 'gi'},
  {name: 'Greece', code: 'gr'},
  {name: 'Ireland', code: 'ie'},
  {name: 'Isle of Man', code: 'im'},
  {name: 'Italy', code: 'it'},
  {name: 'Latvia', code: 'lv'},
  {name: 'Lithuania', code: 'lt'},
  {name: 'Malta', code: 'mt'},
  {name: 'Mexico', code: 'mx'},
  {name: 'Michigan', code: 'us-mi'},
  {name: 'Morocco', code: 'ma'},
  {name: 'Netherlands', code: 'nl'},
  {name: 'New Jersey', code: 'us-nj'},
  {name: 'Norway', code: 'no'},
  {name: 'Ontario', code: 'ca-on'},
  {name: 'PAiG', code: 'us-pa'},
  {name: 'PAiL', code: 'us-pail'},
  {name: 'Peru', code: 'pe'},
  {name: 'Portugal', code: 'pt'},
  {name: 'Quebec', code: 'ca-qc'},
  {name: 'Romania', code: 'ro'},
  {name: 'Serbian', code: 'rs'},
  {name: 'Spain', code: 'es'},
  {name: 'Sweden', code: 'se'},
  {name: 'Turkey', code: 'tr'},
  {name: 'UK', code: 'uk'},
  {name: 'West Virginia', code: 'us-wv'}
];

/* ************************************************************************
 * Cookie stuff
 */
 export function SG_setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  export function SG_getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
