export function PostData(type, userData) {
  let BaseURL = './backend.php';

  console.log(userData);

  return new Promise((resolve, reject) =>{
    fetch(BaseURL, {
      method: 'POST',
      body: JSON.stringify(userData)
    })
    .then(response => {
      console.log(response);
      return response.text()
    })
    .then((data) => {
      resolve(data ? JSON.parse(data) : {})
    })
    .catch((error) => {
      reject(error);
    });
  });
}
