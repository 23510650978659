import React, { Component } from 'react';

class Welcome extends Component {
  render(){
    return (
      <div className="componentContainer">
        <div className="centered-element">
            <h1>Documentation</h1>
            <div className="grid-container">
              <div className="grid-item">
                <a href="https://app.box.com/s/t5tjc07x2ae1ngz7697urmb6eq9ch15i/folder/86913168963" target="_blank">
                  SG Documentation
                </a>
              </div>
            </div>
        </div>
      </div>
    )
  }
}

export default Welcome;
